import React, { useState, useEffect } from 'react';
import InputTextField from '../input-text';
import Selector from '../selector';
import { useAPI } from '../../utils/api';

const AddressSelector = ({
  marginBottom,
  cityId,
  districtId,
  citySelect,
  districtSelect,
  address,
  onlyAddress = false,
}) => {
  const api = useAPI();
  const [cities, setCities] = useState([]);
  const [cityIndex, setCityIndex] = useState(0);
  const [districts, setDistricts] = useState([]);
  const [districtIndex, setDistrictIndex] = useState(0);
  const [showExpandType, setShowExpandType] = useState(0);

  const getDistrict = (cityId) => {
    api
      .getDistricts({ cityId })
      .then((res) => {
        if (res.length > 0) {
          const districtArr = [];
          var index = 0;

          let result = res.find((item) => {
            return (
              item.id.toString() ===
              (districtId !== undefined ? districtId.toString() : '')
            );
          });
          if (result !== undefined && result !== null) {
            let index = res.indexOf(result);
            setDistrictIndex(index);
          }
          res.map((ds) => {
            districtArr.push({
              value: ds.id,
              text: ds.name,
            });
          });

          setDistricts(districtArr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // api
    //   .getCities()
    //   .then((res) => {
    //     if (res.length > 0) {
    //       const cityArr = [];
    //       var index = 0;
    //       var isSelect = false;
    //       for (const cities of res) {
    //         if (cities.id.toString() === cityId.toString()) {
    //           isSelect = true;
    //           setCityIndex(index);
    //         }
    //         cityArr.push({
    //           value: cities.id,
    //           text: cities.name,
    //         });
    //         index = index + 1;
    //       }
    //       setCities(cityArr);
    //       getDistrict(isSelect ? cityId : cityArr[0].value);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    getCities();
  }, []);

  const getCities = () => {
    api
      .getCities()
      .then((res) => {
        if (res.length > 0) {
          const cityArr = [];
          var index = 0;
          for (const cities of res) {
            if (cities.name === '釣魚台') {
              continue;
            }
            cityArr.push({
              value: cities.id,
              text: cities.name,
            });
            index = index + 1;
          }
          setCities(cityArr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (typeof cities !== 'object' || cities.length <= 0) return;
    var result = undefined;
    // if (
    //   cityId === '51' &&
    //   (districtId === undefined ||
    //     districtId === null ||
    //     districtId === '' ||
    //     districtId === '0')
    // ) {
    //   result = cities.find(
    //     (item) => item.value === cityId && item.text === '釣魚台'
    //   );
    // } else
    // {
    result = cities.find((item) => item.value === cityId);
    // }

    if (result !== undefined && result !== null && result !== '') {
      const index = cities.indexOf(result);
      setCityIndex(index);
      // if (result.value === '51' && result.text === '釣魚台') setDistricts([]);
      // else
      // {
      getDistrict(result.value);
      // }
    } else {
      setCityIndex(0);
      setDistricts([]);
    }
  }, [cityId, cities]);

  useEffect(() => {
    if (typeof districts !== 'object' || districts.length <= 0) return;
    const result = districts.find((item) => item.value === districtId);
    if (result !== undefined && result !== null && result !== '') {
      const index = districts.indexOf(result);
      setDistrictIndex(index);
    } else {
      setDistrictIndex(0);
    }
  }, [districtId, districts]);

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const onCitySelect = (cityId, name) => {
    citySelect(cityId);
    // if (cityId === '51' && name === '釣魚台') {
    //   setDistricts([]);
    //   setDistrictIndex(0);
    //   districtSelect();
    // } else
    getDistrict(cityId);
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const onDistrictSelect = (districtId) => {
    console.log('districtId', districtId);
    districtSelect(districtId);
  };

  return (
    <>
      <div className="address-box">
        <label className="label">會員地址</label>
        {!onlyAddress ? (
          <div className="select-container">
            <Selector
              options={cities}
              placeholder="縣市"
              selectedIndex={cityIndex}
              onSelect={onCitySelect}
              isUp="true"
              showExpand={showExpandType === 1}
              setExpand={() => {
                setShowExpandType(1);
              }}
            />
            <Selector
              options={districts}
              placeholder="區鄉市鎮"
              selectedIndex={districtIndex}
              onSelect={onDistrictSelect}
              isUp="true"
              showExpand={showExpandType === 2}
              setExpand={() => {
                setShowExpandType(2);
              }}
            />
          </div>
        ) : (
          <></>
        )}
        {/* <InputTextField placeholder="地址" value={address} /> */}
      </div>

      <style jsx>
        {`
          .address-box {
            margin-bottom: ${marginBottom || 0}px;
            .label {
              font-family: Noto Sans TC;
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 26px;
              color: #333333;
              display: block;
              margin-bottom: 8px;
            }

            .select-container {
              display: grid;
              grid-template-columns: 1fr 1fr;
              column-gap: 12px;
              margin-bottom: 12px;
            }
          }
        `}
      </style>
    </>
  );
};

export default AddressSelector;
