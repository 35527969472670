import React, { useState, useEffect } from 'react';

import IconArrowDown from '../../images/icons/angle-arrow-down-green.svg';

const Selector = ({
  id,
  label,
  options = [],
  selectedIndex,
  onSelect,
  placeholder,
  isUp = false,
  showExpand = false,
  setExpand = () => {},
}) => {
  const [selectedID, setSelectedID] = useState(-1);
  const [expandDropdown, setExpandDropdown] = useState(false);
  const [name, setName] = useState('');

  const toggleSelect = () => {
    setExpandDropdown(!expandDropdown);
    if (!expandDropdown === true) {
      setExpand();
    }
  };

  useEffect(() => {
    if (!showExpand) {
      setExpandDropdown(false);
    }
  }, [showExpand]);

  // const updateSelected = (id) => {
  //   setSelectedID(id);
  //   setExpandDropdown(false);
  // };

  // useEffect(() => {
  //   updateSelected(selectedIndex);
  //   // console.log('selectedIndex', selectedIndex);
  //   if (onSelect && selectedID > -1) {
  //     onSelect(selectedID);
  //   }
  // }, [selectedIndex, selectedID, onSelect]);

  const didSelect = (index) => {
    setName(options[index].text);
    setExpandDropdown(false);
    onSelect(options[index].value, options[index].text);
  };

  useEffect(() => {
    console.log('options', options);
    console.log('selectedIndex', selectedIndex);
    if (options.length > 0) {
      setName(options[selectedIndex].text);
    } else {
      setName('');
    }
  }, [options, selectedIndex]);

  return (
    <>
      <div className="container">
        {label ? (
          <>
            <label className="label">{label}</label>
          </>
        ) : (
          <></>
        )}
        <div className="outbox">
          <img className="arrow" src={IconArrowDown} alt="arrow down" />
          <input
            className={`box ${expandDropdown ? 'focused' : ''}`}
            id={id}
            name={id}
            readOnly
            placeholder={placeholder}
            value={name}
            onClick={toggleSelect}
          />
        </div>
        {expandDropdown && options.length > 0 ? (
          <div className={`dropdown ${isUp ? 'up' : ''}`}>
            {options.map((option, index) => (
              <div
                key={`${option.value}-${option.text}`}
                className="option"
                onClick={() => {
                  console.log('click', index);
                  didSelect(index);
                }}
              >
                {option.text}
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>

      <style jsx>
        {`
          .container {
            position: relative;

            .label {
              display: block;
              margin-bottom: 16px;
            }

            .outbox {
              position: relative;

              .box {
                box-sizing: border-box;
                background-color: #fdfeff;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                display: block;
                border: 1px solid #c1c1c1;
                border-radius: 3px;
                padding: 16px;
                width: 100%;
                font-size: 16px;
                cursor: pointer;
                color: #333333;

                &:focus,
                &:focus-visible {
                  border: 1px solid #c1c1c1;
                  border-radius: 3px;
                  outline: 0;
                }
              }

              .focused {
                border: 1px solid #5fd2da;
                border-radius: 3px;
                outline: 1px solid #5fd2da;

                &:focus,
                &:focus-visible {
                  border: 1px solid #5fd2da;
                  border-radius: 3px;
                  outline: 1px solid #5fd2da;
                }
              }

              .arrow {
                position: absolute;
                width: 16px;
                height: 16px;
                top: 50%;
                right: 16px;
                transform: translate(0, -50%);
              }
            }

            .dropdown {
              z-index: 10;
              max-height: 200px;
              overflow-y: scroll;
              width: 100%;
              position: absolute;
              background-color: #fdfeff;
              margin-top: 8px;
              border: 1px solid #c1c1c1;
              border-radius: 4px;
              box-shadow: #c1c1c1 2px 2px 8px;

              &.up {
                transform: translate(0, -270px);
              }

              .option {
                padding: 16px;
                cursor: pointer;

                &:hover {
                  background-color: #cafbff;
                }
              }
            }
          }
        `}
      </style>
    </>
  );
};

export default Selector;
