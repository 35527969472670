import React, { useEffect, useState } from 'react';
import InputTextField from '../input-text';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';

import IconCalender from '../../images/icons/i-calender.svg';

import 'react-datepicker/dist/react-datepicker.css';

import zhTW from 'date-fns/locale/zh-TW';
registerLocale('zh-TW', zhTW);

const DateField = ({
  label,
  marginBottom,
  htmlFor,
  dateValue,
  onDateChange = () => {},
  format = 'yyyy-MM-dd',
  disable,
}) => {
  const [startDate, setStartDate] = useState(dateValue);

  useEffect(() => {
    onDateChange(startDate);
  }, [startDate]);

  return (
    <>
      <div className="date-picker-container">
        <label className="label" htmlFor={htmlFor}>
          {label}
        </label>
        <div className="date-picker-box">
          <img className="icon-calender" src={IconCalender} alt="calender" />
          <DatePicker
            className="date-picker"
            locale="zh-TW"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat={format}
            disabled={disable}
          />
        </div>
      </div>

      <style jsx>
        {`
          .date-picker-container {
            margin-bottom: ${marginBottom ? `${marginBottom}px` : '0'};

            .label {
              font-family: Noto Sans TC;
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 26px;
              color: #333333;
              display: block;
              margin-bottom: 8px;
            }

            .date-picker-box {
              background-color: #fff;
              border-radius: 3px;
              position: relative;

              .icon-calender {
                position: absolute;
                top: 50%;
                right: 14px;
                transform: translate(0, -50%);
              }
            }
          }
        `}
      </style>
    </>
  );

  // return (
  //   <>
  //     <InputTextField
  //       label={label}
  //       name={name}
  //       marginBottom={marginBottom}
  //       type="date"
  //       text={value}
  //       onChange={(e) => console.log(e.target.value)}
  //     />
  //   </>
  // );
};

export default DateField;
